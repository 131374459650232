<template>
    <div>
        <div class="form-signin">
            <h3>Verfiy Email</h3>
            <div>
                <span>ID : </span>
                <span>{{ state.form.body.id }}</span>
            </div>
            <div>
                <span>token : </span>
                <span>{{ state.form.body.token }}</span>
            </div>
            <button
                @click="verifyEmail"
                class="btn btn-lg btn-primary btn-block"
            >Verify</button>
        </div>
    </div>
</template>

<script>
import { onMounted } from 'vue';
import { reactive } from 'vue';
import { useRoute } from 'vue-router'

import axios from 'axios';

export default {

    name: 'Verify-Email-Form',

    components : {
        //
    },

    setup() {

        const route = useRoute()

        const state = reactive({
            form: {
                body: {
                    id : '',
                    token: null,
                },
            },

        })

        onMounted(() => {
            state.form.body.id = route.params.id
            state.form.body.token = route.params.token
        })

        async function verifyEmail() {

            await axios({
                method: 'get',
                url: 'auth/email/verify/' + state.form.body.id + '/' + state.form.body.token,
            })
            .then(() => {
                console.log('verify successful')
            })
            .catch(() => {
                console.log('error in verification process')
            })
        }

        return {
            state,
            verifyEmail
        }

    },

}

</script>

<style>
    @import '../../assets/css/signin.css';
</style>
